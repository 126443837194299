import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { mealPass } from "config/apiCall";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "store";
import { startLoading, stopLoading } from "store/slices/loading.slice";
import { handleKeyDownUpInputBlur, handleScrollInputBlur } from "utils";
import { SetTabChange } from "./DonarForm";
import { toast } from "react-toastify";

interface MealSlotItems {
  date: string;
  mealSlot: number[];
}

interface Props extends SetTabChange {
  personToken: string;
}

interface FormValues {
  startDate: string;
  endDate: string;
  noOfPerson: number;
  mealSlot: { date: string; mealSlot: number[] }[];
}

const SelectSlot: FC<Props> = ({ setValue: setTabChange, personToken }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<FormValues>();
  const dispatch = useAppDispatch();

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: moment(watch("startDate")).format("YYYY-MM-DD"),
      endDate: moment(watch("endDate")).format("YYYY-MM-DD"),
    };
    // eslint-disable-next-line
  }, [watch("startDate"), watch("endDate")]);

  const getDatesBetween = (startDate: string, endDate: string) => {
    const dates = [];
    let currentDate = moment(startDate);

    while (currentDate <= moment(endDate)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "days");
    }

    return dates;
  };

  useEffect(() => {
    if (startDate && endDate) {
      const datesBetween = getDatesBetween(startDate, endDate);
      setValue(
        "mealSlot",
        datesBetween.map((elem) => ({ date: elem, mealSlot: [] }))
      );
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const onSubmit = (data: FormValues) => {
    dispatch(startLoading());
    const { startDate, endDate, ...rest } = data;

    mealPass
      .post({ ...rest, personToken })
      .then(({ data: { message } }) => {
        reset();
        setTabChange(0);
        toast.success(message, {
          position: "top-right",
        });
      })
      .catch((error) => {
        console.log("error >>", error.response.data.message);
        toast.error(error.response.data.message || error.message, {
          position: "top-right",
        });
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  const handleChangeSlot = (checked: boolean, id: number, slot: number) => {
    const old = [...watch("mealSlot")];
    if (checked) {
      old[id]["mealSlot"].push(slot);
    } else {
      old[id]["mealSlot"] = old[id]["mealSlot"].filter(
        (s: number) => s !== slot
      );
    }
    setValue("mealSlot", old);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-5 lg:my-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 ">
        <div className="mb-4 col-span-4 sm:col-span-1 md:col-span-2">
          <TextField
            id="outlined-required"
            label="Person"
            fullWidth
            {...register("noOfPerson", {
              required: "No. of person is required.",
            })}
            type="number"
            error={!!errors.noOfPerson}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            autoComplete="off"
            inputProps={{ min: 1 }}
            onWheel={handleScrollInputBlur}
            onKeyDown={handleKeyDownUpInputBlur}
            onKeyUp={handleKeyDownUpInputBlur}
          />
        </div>
        <div className="mb-4 col-span-4 sm:col-span-1 md:col-span-1">
          <TextField
            id="outlined-required"
            label="Start Date"
            fullWidth
            {...register("startDate", {
              required: "Start Date is required.",
            })}
            type="date"
            error={!!errors.startDate}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            autoComplete="off"
          />
        </div>
        <div className="mb-4 col-span-4 sm:col-span-1 md:col-span-1">
          <TextField
            id="outlined-required"
            label="End Date"
            fullWidth
            {...register("endDate", {
              required: "End Date is required.",
            })}
            type="date"
            error={!!errors.endDate}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            autoComplete="off"
          />
        </div>
        {watch("mealSlot")
          ? watch("mealSlot")?.map((elem: MealSlotItems, i: number) => (
              <div className="mb-4 col-span-4 flex items-center">
                <div className="w-full">
                  <p>{elem.date}</p>
                </div>
                <div className="flex justify-between w-full flex-wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangeSlot(e.target.checked, i, 1)
                        }
                        checked={elem.mealSlot.includes(1)}
                      />
                    }
                    label="Break Fast"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangeSlot(e.target.checked, i, 2)
                        }
                        checked={elem.mealSlot.includes(2)}
                      />
                    }
                    label="Lunch"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangeSlot(e.target.checked, i, 3)
                        }
                        checked={elem.mealSlot.includes(3)}
                      />
                    }
                    label="Dinner"
                  />
                </div>
              </div>
            ))
          : ""}
      </div>
      <div className="text-end my-5">
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default SelectSlot;
