import { PersonMasterType } from "config";
import api from "./api";

export const SERVICES = {
  PRIVATE: "/hisab-kitab",
  PUBLIC: "/hisab-kitab/public",
};
export const V1 = "/api/v1";

export const personMaster = {
  get: (params: PersonMasterType) =>
    api.get(`${SERVICES.PUBLIC}${V1}/personMaster/detail`, {
      params,
    }),
  post: (formData: any) =>
    api.post(`${SERVICES.PUBLIC}${V1}/personMaster`, formData),
};

export const mealPass = {
  post: (formData: any) =>
    api.post(`${SERVICES.PUBLIC}${V1}/meal-pass`, formData),
};
