import { KeyboardEvent, WheelEvent } from "react";

export const handleScrollInputBlur = (e: WheelEvent<HTMLInputElement>) => {
  return e.currentTarget.blur();
};

export const handleKeyDownUpInputBlur = (
  e: KeyboardEvent<HTMLInputElement>
) => {
  return e.currentTarget.blur();
};
