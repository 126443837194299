import React, { FC, useState } from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DonarForm from "./DonarForm";
import SelectSlot from "./SelectSlot";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const MealPass: FC = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [personToken, setPersonToken] = useState("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="h-screen">
      <div className="flex flex-col justify-center min-h-[100%]">
        <div className="lg:w-[80vw] xl:w-[41vw] bg-white mx-auto min-h-[50vh]">
          <div className="px-5">
            <div>
              <h1 className="text-2xl text-center font-black py-5">Register</h1>
            </div>

            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="User Register" {...a11yProps(0)} disabled />
                <Tab label="Select Slot" {...a11yProps(1)} disabled />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <DonarForm
                setValue={setValue}
                personToken={personToken}
                setPersonToken={setPersonToken}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <SelectSlot setValue={setValue} personToken={personToken} />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealPass;
