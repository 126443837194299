import { FC } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useAppSelector } from "store";
import { CircularProgress } from "@mui/material";

const Loading: FC = () => {
  const isLoading = useAppSelector((state) => state.loading.isLoading);

  return isLoading ? (
    <div className="bg-[#a7caed6b] h-screen fixed z-10 top-0 left-0 w-screen">
      <LinearProgress />
      <div className="flex justify-center items-center h-full">
        <CircularProgress size={70} />
      </div>
    </div>
  ) : null;
};

export default Loading;
