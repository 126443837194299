import { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MealPass from './public/mealPass/Index';

const Index: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:orgId/:templeUuid" element={<MealPass />} />
      </Routes>
    </Router>
  );
};

export default Index;
