import { FC, useEffect, useMemo } from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DialCodeData from 'data/dialCode.json';
import { personMaster } from 'config/apiCall';
import { useAppDispatch } from 'store';
import { startLoading, stopLoading } from 'store/slices/loading.slice';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export interface SetTabChange {
  setValue: (val: number) => void;
}

interface Props extends SetTabChange {
  setValue: (val: number) => void;
  setPersonToken: (value: string) => void;
  personToken: string;
}

const DonarForm: FC<Props> = ({ setValue: setTabChange, personToken, setPersonToken }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue
  } = useForm<FieldValues>();
  const dispatch = useAppDispatch();
  const { orgId, templeUuid } = useParams();

  const dialCodes = useMemo(() => {
    return DialCodeData;
  }, []);

  const getExistingDonarDetails = () => {
    if (!orgId) return;
    const params = {
      mobile: `${watch('dialCode')?.value} ${watch('contactNo')}`,
      orgId: +orgId
    };
    dispatch(startLoading());
    personMaster
      .get(params)
      .then((response) => {
        const { mobile, ...rest } = response.data.payload.data;
        const dialCode = dialCodes.find((elem) => mobile.includes(elem.value));
        reset({
          dialCode,
          ...rest
        });
        setPersonToken(`Bearer ${response.data.payload.personToken}`);
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  useEffect(() => {
    if (watch('contactNo') && watch('contactNo').length === 10) {
      getExistingDonarDetails();
    } else {
      setValue('name', '');
      setValue('village', '');
      setValue('city', '');
      setValue('state', '');
      setValue('country', '');
      setValue('address', '');
    }
    // eslint-disable-next-line
  }, [watch('contactNo')]);

  const onSubmit = (data: any) => {
    if (personToken) {
      setTabChange(1);
      return;
    }

    dispatch(startLoading());
    const { dialCode, contactNo, ...rest } = data;

    personMaster
      .post({ mobile: `${dialCode.value} ${contactNo}`, ...rest, orgId, templeUuid })
      .then(({ data: { message, payload } }) => {
        reset();
        setTabChange(1);
        toast.success(message, {
          position: 'top-right'
        });
        setPersonToken(`Bearer ${payload.personToken}`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-5 lg:my-10 grid grid-cols-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
          <div className="flex col-span-4 sm:col-span-2 md:col-span-2 mb-4">
            <Controller
              name="dialCode"
              control={control}
              defaultValue={null}
              rules={{ required: 'Dial code is required.' }}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  options={dialCodes}
                  defaultValue={field.value}
                  onChange={(event, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dial Code *"
                      error={!!errors.dialCode}
                      slotProps={{
                        inputLabel: {
                          shrink: true
                        }
                      }}
                      autoComplete="off"
                    />
                  )}
                  fullWidth
                  className="!w-[180px]"
                />
              )}
            />
            <TextField
              id="outlined-required"
              label="Contact *"
              fullWidth
              {...register('contactNo', {
                required: 'contact no is required.'
              })}
              error={!!errors.contactNo}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              autoComplete="off"
            />
          </div>
          <div className="mb-4 col-span-4 sm:col-span-1 md:col-span-2">
            <TextField
              id="outlined-required"
              label="Name *"
              fullWidth
              {...register('name', {
                required: 'Name is required.'
              })}
              error={!!errors.name}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              autoComplete="off"
            />
          </div>
          <div className="mb-4 col-span-4 sm:col-span-2 md:col-span-2">
            <TextField
              id="outlined-required"
              label="DOB *"
              fullWidth
              {...register('dob', {
                required: 'Date Of Birth is required.'
              })}
              type="date"
              error={!!errors.dob}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              autoComplete="off"
            />
          </div>
          <div className="mb-4 col-span-4 sm:col-span-2 md:col-span-2">
            <TextField
              id="outlined-required"
              label="Village *"
              fullWidth
              {...register('village', {
                required: 'Village is required.'
              })}
              error={!!errors.village}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              autoComplete="off"
            />
          </div>
          <div className="mb-4 col-span-4 sm:col-span-1">
            <TextField
              id="outlined-required"
              label="City"
              fullWidth
              {...register('city')}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              autoComplete="off"
            />
          </div>
          <div className="mb-4 col-span-4 sm:col-span-1">
            <TextField
              id="outlined-required"
              label="State"
              fullWidth
              {...register('state')}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
            />
          </div>
          <div className="mb-4 col-span-4 sm:col-span-2 md:col-span-2">
            <TextField
              id="outlined-required"
              label="Country"
              fullWidth
              {...register('country')}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              autoComplete="off"
            />
          </div>
          <div className="mb-4 col-span-4">
            <TextField
              id="outlined-required"
              label="Address"
              fullWidth
              {...register('address')}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="text-end my-5">
          <Button variant="contained" type="submit" endIcon={<NavigateNextIcon />}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DonarForm;
