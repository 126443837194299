import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "./screens";
import Loading from "components/common/Loading";
import { Provider } from "react-redux";
import { store } from "store";

function App() {
  return (
    <Provider store={store}>
      <Loading />
      <ToastContainer />
      <Index />
    </Provider>
  );
}

export default App;
